

body.not-found-page {
    background: #fff; /* Change this to your desired color */
  }
body {
	
	background: #8dcae4;
	padding-top: 42px;
	
}

@media (max-width: 576px) {
    .margin-left-sm {
      margin-left: 90px;
    }
  }

  

  
  .btn-ripple {
	display: inline-block;
	position: relative;
	overflow: hidden;
	transition: all ease-in-out .5s;
}

.btn-ripple::after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 25%;
	height: 100%;
	width: 50%;
	background-color: #87ceeb;
	border-radius: 50%;
	opacity: 0;
	pointer-events: none;
	transition: all ease-in-out 1s;
	transform: scale(5, 5);
}

.btn-ripple:active::after {
	padding: 0;
	margin: 0;
	opacity: 10;
	transition: 0s;
	transform: scale(0, 0);
}


